import * as React from "react";

import { FormSpec, localized, multiSelectSpec, multiSelectStylePicker } from "../../../form-specs";
import { LocalizedTitleOptions, getWidgetTitleOptions, titleStylingOptions } from "../../../components/widgetTitleAndLabel/localizedLableTitle.util";

import { DynamicCustomerSummary } from "./CustomerSummary";
import { LocalizedReservationStatus } from "../../../utils/reservation.util";
import { PageWidgetSpec } from "../../widget";
import { WidgetGroup } from "../../widget.enum";
import { findMultiSelectStyleClassNames } from "../../../themes";
import { getI18nLocaleObject } from "../../../i18n";
import namespaceList from "../../../i18n/namespaceList";

export interface WidgetOptions extends LocalizedTitleOptions {
    styleIds?: string[];
    visibleOptions: Array<{ label: string; value: customerOptions }>;
    localizedReservationStatus: LocalizedReservationStatus[];
    hideCustomerSummaryTitle?: boolean;
    hideFullAddressCommaSeparator?: boolean;
}
const TARGETS = ["customer-summary"];

export enum customerOptions {
    reservationNumber = "reservationNumber",
    firstName = "firstName",
    middleName = "middleName",
    lastName = "lastName",
    email = "email",
    birthDate = "birthDate",
    phoneNumber = "phoneNumber",
    address = "address",
    city = "city",
    state = "state",
    zipCode = "zipCode",
    country = "country",
    fullAddress = "fullAddress",
    companyName = "companyName",
    houseNumber = "houseNumber",
    houseNumberSuffix = "houseNumberSuffix",
    address1 = "address1",
    title = "title",
}

const generateVisibleOptions = () => {
    const visibleOptions = [];
    for (const customerOption in customerOptions) {
        if (Object.prototype.hasOwnProperty.call(customerOptions, customerOption)) {
            const option = {
                value: customerOption,
                text: getI18nLocaleObject(namespaceList.customerSummaryWidget, customerOption),
            };
            visibleOptions.push(option);
        }
    }
    return visibleOptions;
};

export const widgetOptionsForm: FormSpec<WidgetOptions> = {
    id: "customer-summary-widget-options",
    name: getI18nLocaleObject(namespaceList.customerSummaryWidget, "customerSummaryOption"),
    pluralName: getI18nLocaleObject(namespaceList.customerSummaryWidget, "customerSummaryOptions"),
    properties: [
        {
            type: "statictabs",
            tabs: [
                {
                    name: getI18nLocaleObject(namespaceList.admin, "general"),
                    properties: [
                        [
                            multiSelectSpec("visibleOptions", getI18nLocaleObject(namespaceList.customerSummaryWidget, "visibleOptions"), true, () => generateVisibleOptions()),
                            {
                                variable: "hideCustomerSummaryTitle",
                                label: getI18nLocaleObject(namespaceList.customerSummaryWidget, "hideCustomerSummaryTitle"),
                                type: "checkbox",
                                default: false,
                                visible: (item: WidgetOptions) => !!item.visibleOptions.length,
                            },
                            {
                                variable: "hideFullAddressCommaSeparator",
                                label: getI18nLocaleObject(namespaceList.customerSummaryWidget, "hideFullAddressCommaSeparator"),
                                type: "checkbox",
                                default: false,
                            },
                            localized({
                                variable: "localizedReservationStatus",
                                tabContent: [
                                    {
                                        variable: "reservationNumberFallbackText",
                                        label: getI18nLocaleObject(namespaceList.admin, "reservationNumberFallbackText"),
                                        type: "text",
                                    },
                                ],
                            }),
                            ...getWidgetTitleOptions<WidgetOptions>(),
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.admin, "styling"),
                    properties: [[...titleStylingOptions<WidgetOptions>(), multiSelectStylePicker("styleIds", TARGETS)]],
                },
            ],
        },
    ],
};

export const customerSummaryWidget: PageWidgetSpec<WidgetOptions> = {
    id: "CustomerSummaryWidget",
    type: "page",
    widgetGroup: WidgetGroup.DYNAMIC,
    name: getI18nLocaleObject(namespaceList.customerSummaryWidget, "customerSummary"),
    description: getI18nLocaleObject(namespaceList.customerSummaryWidget, "customerSummaryDescription"),
    optionsForm: widgetOptionsForm,
    defaultOptions: (): WidgetOptions => ({
        visibleOptions: [],
        localizedReservationStatus: [],
    }),
    async render(widget, context) {
        const { styleIds } = widget.options;
        const className = findMultiSelectStyleClassNames(context.theme, TARGETS, styleIds || []);
        return <DynamicCustomerSummary options={widget.options} context={context} className={className} />;
    },
};
