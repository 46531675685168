import * as React from "react";
import * as classNames from "classnames";

import { Country, Customer, CustomerFullAddress, CustomerTitle, EsReservation, ReservationResult } from "@maxxton/cms-mxts-api";
import { WidgetOptions, customerOptions } from "./";

import { Badge } from "reactstrap";
import { DynamicWidgetBaseProps } from "../dynamicWidget.types";
import LocalizedTitleAndLabel from "../../../components/widgetTitleAndLabel/LocalizedLableTitle";
import { getI18nLocaleString } from "../../../i18n";
import { getLocalizedContent } from "../../../utils/localizedContent.util";
import { getMxtsEnv } from "../../mxts";
import { getReservationNumberOrFallbackText } from "../../../utils/reservation.util";
import { getSharedOwnershipWithSharedOwnerId } from "../../../utils/sharedOwnership.util";
import { kebabCase } from "lodash";
import namespaceList from "../../../i18n/namespaceList";
import { setOpacityOnHide } from "../../../components/utils";

interface CustomerSummaryReservationInfo {
    reservationId: number;
    reservationNumber: string;
    customerId: number;
}

interface CustomerSummarySimpleProps extends DynamicWidgetBaseProps<WidgetOptions> {
    reservation?: ReservationResult | EsReservation;
    customer?: Customer;
    currentAddress?: CustomerFullAddress;
}

// eslint-disable-next-line max-lines-per-function
export const SimpleCustomerSummary = (props: CustomerSummarySimpleProps) => {
    const { className, options, reservation, customer, currentAddress, context } = props;
    const { visibleOptions, enableWidgetTitle, useTitleHeadings, styleWidgetTitle, fontColor } = options;
    const { currentLocale, site } = context;
    const hideWidget = setOpacityOnHide(options);
    const [country, setCountry] = React.useState<Country>();
    const [title, setTitle] = React.useState<CustomerTitle>();
    const [isSharedOwner, setIsSharedOwner] = React.useState<boolean>(false);

    React.useEffect(() => {
        const obtainCountry = async () => {
            if (currentAddress?.countryId) {
                const env = await getMxtsEnv(context);
                const countries = await context.mxtsApi.countries(env, { size: 1, countryIds: [currentAddress.countryId] });
                setCountry(countries?.content[0]);
            }
        };
        obtainCountry();
    }, [currentAddress?.countryId]);

    React.useEffect(() => {
        const obtainSharedOwnership = async () => {
            const { showSharedOwnership } = context.site;
            if (showSharedOwnership && customer?.owner) {
                const env = await getMxtsEnv(context);
                const sharedOwnership = await getSharedOwnershipWithSharedOwnerId(env, [customer.customerId]);
                if (sharedOwnership?.length) {
                    setIsSharedOwner(true);
                }
            }
        };
        obtainSharedOwnership();
    }, [customer]);

    React.useEffect(() => {
        const obtainTitle = async () => {
            if (customer?.titleId) {
                const env = await getMxtsEnv(context, context.currentLocale?.code);
                const title = (await context.mxtsApi.getCustomerTitles(env, {})).find((customerTitle: CustomerTitle) => customerTitle.titleId === customer?.titleId);
                setTitle(title);
            }
        };
        obtainTitle();
    }, [customer?.titleId]);

    const localizedWidgetTitle: string = getLocalizedContent({ site, currentLocale, localizedContent: options.localizedWidgetTitle || [], keys: ["widgetTitleText"] })?.widgetTitleText || "";
    const getValue = (field: { label: string; value: customerOptions }) => {
        const {
            context,
            options: { localizedReservationStatus, hideFullAddressCommaSeparator },
        } = props;

        const { reservationNumber } = (reservation || {}) as CustomerSummaryReservationInfo;

        const { firstName, lastName, middle, birthDate, companyName } = customer || {};
        const { mobilePhone, email } = customer?.addresses?.[0] || {};

        switch (field.value) {
            case customerOptions.address:
                if (currentAddress) {
                    const fullAddress = [currentAddress.address1, currentAddress.address2, currentAddress.address3, currentAddress.houseNumber, currentAddress.houseNumberSuffix].filter(
                        (address) => address
                    );
                    return fullAddress.join(", ");
                }
                return null;
            case customerOptions.fullAddress:
                if (currentAddress) {
                    const fullAddress = [
                        currentAddress.address1,
                        currentAddress.address2,
                        currentAddress.address3,
                        currentAddress.houseNumber,
                        currentAddress.houseNumberSuffix,
                        currentAddress.city,
                        currentAddress.stateName,
                        currentAddress.zipCode,
                        country?.longName,
                    ].filter((address) => address);
                    return fullAddress.join(`${hideFullAddressCommaSeparator ? " " : ", "}`);
                }
                return null;
            case customerOptions.city:
                return currentAddress?.city;
            case customerOptions.state:
                return currentAddress?.stateName;
            case customerOptions.zipCode:
                return currentAddress?.zipCode;
            case customerOptions.country:
                return country?.longName;
            case customerOptions.birthDate:
                return birthDate;
            case customerOptions.email:
                return email;
            case customerOptions.title:
                return title?.title;
            case customerOptions.firstName:
                return firstName;
            case customerOptions.lastName:
                return lastName;
            case customerOptions.middleName:
                return middle;
            case customerOptions.phoneNumber:
                return mobilePhone;
            case customerOptions.companyName:
                return companyName;
            case customerOptions.houseNumber:
                return currentAddress?.houseNumber;
            case customerOptions.houseNumberSuffix:
                return currentAddress?.houseNumberSuffix;
            case customerOptions.address1:
                return currentAddress?.address1;
            case customerOptions.reservationNumber:
                return reservationNumber ? reservationNumber.substring(0, reservationNumber.length - 3) : getReservationNumberOrFallbackText({ context, localizedReservationStatus });
            default:
                return null;
        }
    };

    return (
        <div className={classNames("customer-summary", hideWidget, className)}>
            <LocalizedTitleAndLabel
                localizedTitle={localizedWidgetTitle}
                enableWidgetTitle={enableWidgetTitle}
                useTitleHeadings={useTitleHeadings}
                styleWidgetTitle={styleWidgetTitle}
                className={classNames("widget-heading", `${fontColor?.includes("theme") && `color-${fontColor}`}`)}
                style={classNames(fontColor?.includes("rgba") && fontColor)}
            />
            {visibleOptions.map((option) => {
                const value = getValue(option);
                return (
                    value && (
                        <div key={option.value} className={`customer-summary__information customer-summary__${kebabCase(option.value)}`}>
                            <span className={classNames("name", { "customer-summary__name-hide": options.hideCustomerSummaryTitle })}>
                                {getI18nLocaleString(namespaceList.customerSummaryWidget, option.value, currentLocale, site)}
                            </span>
                            <span className="value">{value}</span>
                        </div>
                    )
                );
            })}
            {isSharedOwner && (
                <div className="shared-ownership-badge-wrapper">
                    <Badge className="shared-ownership-badge" pill>
                        {getI18nLocaleString(namespaceList.admin, "sharedOwnership", currentLocale, site)}
                    </Badge>
                </div>
            )}
        </div>
    );
};
