import * as React from "react";

import { ReservationCustomerSagaAction, fetchReservationCustomerInfo } from "../../../redux/actions/reservationCustomerAction";
import { WidgetOptions, customerOptions } from "./";
import { getMyEnvAddress, getMyEnvMainCustomer, getSelectedMyEnvReservation } from "../../../redux/reducers/myEnv/myEnvState.util";

import { Dispatch } from "redux";
import { DynamicFilter } from "../../../redux/reducers/dynamicFilter.types";
import { DynamicWidgetBaseProps } from "../dynamicWidget.types";
import { MyEnvState } from "../../../redux/reducers/myEnv/myEnvState";
import { ReservationContainerCRPProps } from "../reservation/container/reservationContainer.types";
import { ReservationCustomerState } from "../../../redux/reducers/reservationCustomerReducer";
import { ReservationState } from "../../../redux/reducers/reservationReducer";
import { SimpleCustomerSummary } from "./SimpleCustomerSummary";
import { State } from "../../../redux";
import { connect } from "react-redux";
import { wrapProps } from "../../../i18n";

interface CustomerSummaryProps extends DynamicWidgetBaseProps<WidgetOptions>, CustomerSummaryStoreProps, CustomerSummaryDispatchProps, ReservationContainerCRPProps {}

interface CustomerSummaryStoreProps {
    myEnvState: MyEnvState;
    dynamicFilter: DynamicFilter;
    customerSummaryStore: ReservationCustomerState;
    reservationStore: ReservationState;
}

interface CustomerSummaryDispatchProps {
    dispatchAction: Dispatch<ReservationCustomerSagaAction>;
}

interface CustomerSummaryState {}

class CustomerSummaryWidget extends React.Component<CustomerSummaryProps, CustomerSummaryState> {
    constructor(props: CustomerSummaryProps) {
        super(props);
        this.state = {};
    }

    public componentDidMount() {
        const { dispatchAction, isMyEnvWidget } = this.props;
        if (!isMyEnvWidget) {
            dispatchAction(fetchReservationCustomerInfo());
        }
    }

    public render(): JSX.Element | null {
        const {
            customerSummaryStore: { currentAddress: storeAddress, customer: storeCustomer },
            reservationStore: { reservation: storeReservation },
            dispatchAction,
            isMyEnvWidget,
            esReservationResult,
            options,
            myEnvState,
        } = this.props;
        const { visibleOptions } = options;
        const showReservationNumber: boolean = visibleOptions.some((option) => option.value === customerOptions.reservationNumber);

        let reservation;
        if (showReservationNumber) {
            reservation = isMyEnvWidget ? getSelectedMyEnvReservation({ myEnvState, esReservationResult, dispatchAction }) : storeReservation;
        }
        const customer = isMyEnvWidget ? getMyEnvMainCustomer({ myEnvState, dispatchAction }) : storeCustomer;
        const address = isMyEnvWidget ? getMyEnvAddress({ myEnvState, dispatchAction }) : storeAddress;

        return <SimpleCustomerSummary {...this.props} reservation={reservation} customer={customer} currentAddress={address}></SimpleCustomerSummary>;
    }
}

function mapStateToProps(state: State): CustomerSummaryStoreProps {
    return {
        dynamicFilter: state.dynamicFilter,
        customerSummaryStore: state.reservationCustomerState,
        reservationStore: state.reservationState,
        myEnvState: state.myEnvState,
    };
}

function mapDispatchToProps(dispatch: Dispatch<ReservationCustomerSagaAction>): CustomerSummaryDispatchProps {
    return { dispatchAction: dispatch };
}

const CustomerSummary = connect<CustomerSummaryStoreProps, CustomerSummaryDispatchProps>(mapStateToProps, mapDispatchToProps)(CustomerSummaryWidget);

export const DynamicCustomerSummary = wrapProps<DynamicWidgetBaseProps<WidgetOptions>>(CustomerSummary);
